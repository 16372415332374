.prevCont{
  position: absolute;
  bottom: 0;
  width: 0;
  box-sizing: border-box;
  z-index: 9999;
}

.prevScreen{
  position: absolute;
  bottom: 6px;
  margin-left: auto;
  width: 100vw;
  height: 100vh;
  display: none;
  transform-origin: bottom left;
  transition: all ease-in-out 200ms;
  pointer-events: none;

  &[data-show="true"]{
    display: block;
    // transform: scale(0.5);
    transform: scale(0.12, 0.12) translate(-44%, 0);
    animation: fadein 1s ease-in-out;
  }

  &:after{
    content: "";
    position: absolute;
    top: -80px;
    left: -60px;
    width: calc(100% + 120px);
    height: calc(100% + 160px);
    background: rgba(224, 224, 224, 0.8);
    z-index: 0;
  }

  .dpShad{
    filter: none;
  }
}

.floatTab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  overflow: hidden;
  border-radius: 6px;
  transition: all ease-in-out 200ms;
  transform-origin: center;
  display: flex;
  flex-direction: column;

  &[data-size="full"] {
    border-radius: 0;
  }

  &[data-size="cstm"] {
    border-radius: 0;
    filter: none;
    border: solid 1px #c7c7c7;
  }

  &[data-hide="true"] {
    top: 0;
    left: 0;
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
  }

  &[data-max="false"] {
    top: calc(100% + 10px);
    transform: scale(0);
    transform-origin: bottom;
    opacity: 0;
    pointer-events: none;
  }

  &[data-size="mini"] {
    top: 10%;
    left: 20%;
    width: 60%;
    height: 80%;
  }
}

.absolute{
  position: absolute;
}

.appFullName[data-white="true"] {
  color: #fefefe;
}

.windowScreen {
  width: 100%;
  flex-grow: 1;

  &[data-dock="true"]{
    height: calc(100% - 26px);
  }
}

.overTool {
  position: absolute;
  top: 0;
  width: auto;
  height: 26px;

  .btab {
    width: 176px;
    height: 100%;
    margin: 0 4px;
    border-radius: 4px 4px 0 0;
    box-shadow: 2px 0 2px rgba(80, 80, 80, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    font-size: 0.72em;

    .uicon {
      color: #505050;
    }
  }
}

.restWindow {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.addCont {
  width: 80%;

  input {
    box-sizing: border-box;
  }
}

.storeNav {
  .uicon {
    padding: 12px 0;
    margin: 0 4px 2px;
    color: #888888;
    position: relative;
    transition: all ease-in-out 100ms;
    border-radius: 4px;

    &[data-payload="true"]::after {
      content: "";
      position: absolute;
      width: 3px;
      left: 0;
      height: 50%;
      border-radius: 10px;
      background: #2570ff;
    }

    &:hover,
    &[data-payload="true"] {
      background: #ffffff;
      color: #2570ff;
    }
  }
}

.frontPage {
  position: relative;
  max-height: calc(100vh - 150px);
  overflow-y: hidden;

  &:after {
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    background: rgb(255,255,255);
    background: -webkit-linear-gradient(bottom, rgba(244, 244, 244, 1) 10%, rgba(135, 135, 135, 0.4) 50%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(bottom, rgba(244, 244, 244, 1) 10%, rgba(135, 135, 135, 0.4) 50%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to top, rgba(244, 244, 244, 1) 10%, rgba(135, 135, 135, 0.4) 50%, rgba(0,0,0,0) 100%);
  }
}

.panelName {
  color: #fefefe;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.4);
}

.storeRibbon {
  display: flex;
  width: max-content;
  padding: 0 28px;

  &::-webkit-scroll-bar {
    width: 0;
  }

  .imageCont::before {
    content: attr(data-var);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fefefe;
    font-size: 0.72em;
    padding: 8px 12px;
    text-transform: capitalize;
    text-shadow: 0 0 4px #222;
  }

  .imageCont:nth-child(1)::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    border: solid 3px #294bff;
  }
}

.msfull {
  width: calc(100% - 61px);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.frontCont {
  // height: 360px;
  border-radius: 8px;
}

.amzApps {
  background: #670cd7;
  background: -webkit-linear-gradient(48deg, rgba(82, 47, 139, 1) 0%, rgba(113, 35, 133, 1) 100%);
  background: -o-linear-gradient(48deg, rgba(82, 47, 139, 1) 0%, rgba(113, 35, 133, 1) 100%);
  background: linear-gradient(138deg, rgba(82, 47, 139, 1) 0%, rgba(113, 35, 133, 1) 100%);
}

.amzGames {
  background: #214458;
  background: -webkit-linear-gradient(47deg, rgba(33,68,88,1) 0%, rgba(9,134,146,1) 100%);
  background: -o-linear-gradient(47deg, rgba(33,68,88,1) 0%, rgba(9,134,146,1) 100%);
  background: linear-gradient(137deg, rgba(33,68,88,1) 0%, rgba(9,134,146,1) 100%);
}

.amzMovies {
  background: #217558;
  background: -webkit-linear-gradient(47deg, rgba(33,117,88,1) 0%, rgba(15,163,69,1) 100%);
  background: -o-linear-gradient(47deg, rgba(33,117,88,1) 0%, rgba(15,163,69,1) 100%);
  background: linear-gradient(137deg, rgba(33,117,88,1) 0%, rgba(15,163,69,1) 100%);
}

.ribcont {
  background: rgba(255, 255, 255, 0.72);
  margin: auto 6px;
  height: auto;
  width: 120px;
  transform-origin: center;
  transition: all ease-in-out 200ms;

  &:hover{
    transform: scale(1.06);
  }

  .uicon {
    color: #aaa;
    margin-right: 2px;
  }

  .bluestar {
    color: #006ff0;
  }
}

.cmdcont{
  height: calc(100% - 24px);
}

.cmdLine {
  font-size: 1.25em;
  color: rgba(255, 255, 255, 0.72);
  font-family: monospace, sans-serif;
  height: 1.2em;
  font-weight: lighter;
  overflow: hidden;
  max-width: 100%;
}

.actmd {
  height: auto;
  display: flex;
}

.ipcmd {
  background: inherit;
  color: inherit;
  font-family: inherit;
  padding-right: 100px;
  // width: calc(100% - 120px);
  // max-width: calc();
  position: relative;
  caret-color: transparent;
  margin: 0;

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 2px;
    background: #ccc;
    animation: blink 1s step-end infinite;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
    caret-color: #ccc;

    &::after{
      display: none;
    }
  }
}

@keyframes blink {
  from,
  to {
    background: transparent;
  }
  50% {
    background: #ccc;
  }
}

.noteText{
  background: inherit;
  color: #111;
  font-family: inherit;
  font-size: 0.88em;
  resize: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  padding: 4px 8px;

  &::selection{
    background: #0074ff;
    color: #fefefe;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}
