.actmenu{
  position: absolute;
  width: 200px;
  background: #f7f7f7;
  z-index: 10000;
  animation: fadein 200ms ease-in-out;
  padding: 4px 0;
  border: solid 1px #ccc;
  border-radius: 2px;

  &[data-hide="true"]{
    display: none;
  }

  &[data-left="false"] .minimenu{
    left: unset;
    right: 98%;
  }
}

.minimenu{
  position: absolute;
  display: none;
  top: 0;
  left: 98%;
  min-width: 160px;
  // min-height: 280px;
  background: #f7f7f7;
  z-index: 9999;
  padding: 4px 0;
  border: solid 1px #aaa;
  animation: fadein 600ms ease-in-out;
}

.menuopt{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 3px 24px;
  font-weight: 500;
  font-size: 12px;
  cursor: default;

  &:hover{
    background: rgba(0, 0, 0, 0.08);

    .minimenu{
      display: block;
    }
  }

  &[data-dsb="true"]{
    color: #aaa;
    font-weight: 400;
  }

  .uicon{
    position: absolute;
    top: 3px;
    left: 4px;
  }

  .prtclk{
    left: unset;
    right: 4px;
  }

  .dotIcon, .checkIcon{
    top: 8px;
    left: 8px;
    right: unset;
  }

  .checkIcon{
    top: 6px;
  }
}

.menuhr{
  position: relative;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 4px 0;
}
